var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"checklists-table"},[_c('b-card',{attrs:{"no-body":""}},[_c('div',{staticClass:"m-2"},[_c('b-row',[_c('b-col',{staticClass:"d-flex align-items-center justify-content-start mb-1 mb-md-0",attrs:{"cols":"7","md":"4"}},[_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('feather-icon',{attrs:{"icon":"SearchIcon"}})],1),_c('b-form-input',{attrs:{"debounce":"500","placeholder":"Поиск"},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}})],1)],1),_c('b-col',{attrs:{"cols":"5","md":"8"}},[_c('div',{staticClass:"d-flex align-items-center justify-content-end"},[(_vm.$store.getters['user/permission']('check_lists:create'))?_c('b-button',{attrs:{"variant":"primary","disabled":_vm.isProcessing,"to":{ name: 'checklist-add' }}},[_c('span',{staticClass:"text-nowrap"},[_vm._v(" Добавить ")])]):_vm._e()],1)])],1)],1),_c('BOverlay',{attrs:{"show":_vm.isProcessing,"opacity":"0.8","variant":"transparent"}},[_c('b-table',{ref:"usersTable",staticClass:"position-relative min-height",attrs:{"hover":"","items":_vm.checklists,"responsive":"","fields":_vm.tableColumns,"sort-by":_vm.sortBy,"sort-desc":_vm.isSortDirDesc,"show-empty":"","empty-text":"Ничего не найдено"},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.isSortDirDesc=$event},"update:sort-desc":function($event){_vm.isSortDirDesc=$event},"row-clicked":_vm.onRowClicked},scopedSlots:_vm._u([{key:"cell(name)",fn:function(ref){
var checklist = ref.item;
return [_c('div',{staticClass:"font-weight-bolder"},[_vm._v(" "+_vm._s(checklist.name.length > 25 ? ((checklist.name.substring(0, 25)) + "...") : checklist.name)+" ")])]}},{key:"cell(tags)",fn:function(ref){
var checklist = ref.item;
return [(checklist.tags.length)?_c('div',_vm._l((checklist.tags),function(tag){return _c('span',{key:tag.id,staticStyle:{"margin":"0.2rem"}},[_c('BBadge',{attrs:{"variant":"light-primary"}},[_vm._v(" "+_vm._s(tag.tag)+" ")])],1)}),0):_c('div',[_vm._v(" Пусто ")])]}},{key:"cell(created_at)",fn:function(ref){
var checklist = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(_vm._f("readableDate")(checklist.created_at))+" ")])]}},{key:"cell(updated_at)",fn:function(ref){
var checklist = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(_vm._f("readableDate")(checklist.updated_at))+" ")])]}}])})],1),(_vm.checklists.length)?_c('div',{staticClass:"mx-2 mb-2"},[_c('b-row',[_c('b-col',{staticClass:"\n            d-flex\n            align-items-center\n            justify-content-center\n            justify-content-sm-start\n            text-muted\n          ",attrs:{"cols":"12","sm":"6"}},[_c('span',{staticClass:"mr-1"},[_vm._v("Показывать по")]),_c('b-form-group',{staticStyle:{"width":"90px","margin":"0"}},[_c('AppSelect',{staticStyle:{"width":"100%"},attrs:{"searchable":false,"label":"title","clearable":false,"options":_vm.perPageOptions},model:{value:(_vm.pagination.perPage),callback:function ($$v) {_vm.$set(_vm.pagination, "perPage", $$v)},expression:"pagination.perPage"}})],1),_c('span',{staticClass:"ml-1"},[_vm._v(" "+_vm._s('из ' + _vm.totalChecklist)+" ")])],1),_c('b-col',{staticClass:"d-flex align-items-center justify-content-center justify-content-sm-end",attrs:{"cols":"12","sm":"6"}},[(_vm.totalPages > 1)?_c('b-pagination',{staticClass:"mb-0 mt-1 mt-sm-0",attrs:{"total-rows":_vm.totalChecklist,"per-page":_vm.pagination.perPage,"first-number":"","last-number":"","limit":_vm.isMobile ? 1 : 5,"prev-class":"prev-item","next-class":"next-item"},scopedSlots:_vm._u([{key:"prev-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon","size":"18"}})]},proxy:true},{key:"next-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronRightIcon","size":"18"}})]},proxy:true}],null,false,1308952388),model:{value:(_vm.pagination.page),callback:function ($$v) {_vm.$set(_vm.pagination, "page", $$v)},expression:"pagination.page"}}):_vm._e()],1)],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }